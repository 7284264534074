@use "./custom.scss";
@use "bootstrap" as *;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "~antd/dist/antd.css";
body {
    margin: 0;
    font-family: "Inter", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #FBFBFB !important;
    color: #000000 !important;
}

* {
    font-family: "Inter", sans-serif !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#hubspot-messages-iframe-container {
    display: none !important;
    z-index: 2147483647;
    position: fixed!important;
    bottom: 0 !important;
}
