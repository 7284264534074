.cc-wrapper {
  margin-top: -306px;
  position: relative;
}

.client-login-wrap {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  .login-card-wrap {
    margin: auto;
  }
}

.stepper {
  display: flex;
  max-width: 768px;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
}

.ant-alert.alert-in-popup {
  margin: 30px 0;
}

// Go to top icon
.go-top {
  background: #1a4975;
  text-align: center;
  border-radius: 30px;
  padding: 7px 5px;
  position: relative;
  right: -50px;
}

.slider-wrap {
  .logo {
    background: #1a4975;
    margin-top: 19px;
    text-align: center;
    display: flex;
    justify-content: center;
    font-family: cursive;
    font-size: 24px;
    font-weight: 600;
    color: #dee90e;
  }
  .collapsed-logo {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 16px;
    img {
      height: 70px;
      @media screen and (max-width: 425px) {
        height: 50px;
      }
    }
  }
  hr.divider {
    border: 3px solid #d6df22;
    border-radius: 10px;
  }
}

.page-header {
  background-color: inherit;
  .header-search {
    width: 300px;
    height: 44px;
    border: none;
    .search-icon {
      display: flex;
      align-items: center;
      height: 100%;
      margin-left: 20px;
    }
    .search-input {
      padding-left: 55px;
      border: 1px solid #1a4975;
      border-radius: 6px;
    }
  }
  .notification-wrap {
    margin-left: 20px;
    border-radius: 6px;
    height: 44px;
    width: 44px;
    background-color: #e2e1e1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .ant-badge-count {
      padding: 0 !important;
    }
  }
}

.ant-page-header {
  background: #fff !important;
  border-bottom: 1px solid #e8e8e8;
}

.ant-layout-header {
  line-height: 0 !important;
}

.primary-heading {
  margin-top: 20px;
  font-size: 22px;
  font-weight: 800;
  color: #1a4975;
}

.hr-line {
  border: 2px solid #d6df22;
  border-radius: 10px;
  max-width: 50px;
  margin-top: -5px;
  margin-left: 0;
}

.sendMoney {
  //   .ant-steps-vertical
  //     > .ant-steps-item
  //     > .ant-steps-item-container
  //     > .ant-steps-item-tail {
  //     left: auto;
  //     right: 29px;
  //     width: 6px;
  //     background-color: #e7e8ea;
  //     display: block;
  //   }
  .ant-steps-vertical .ant-steps-item-icon {
    position: absolute !important;
    right: 0;
    font-size: 16px;
    font-weight: 400;
    z-index: 3;
    background-color: #e7e8ea;
    border-color: #e7e8ea;
  }
 
  .ant-steps-item-process .ant-steps-item-icon,
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-icon {
    background: #d6df22 !important;
    border-color: #d6df22;
    .ant-steps-icon {
      color: #1a4975 !important;
    }
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content {
    .ant-steps-item-title {
      color: #6a6e72;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .ant-steps-item-container > .ant-steps-item-content {
    top: -40px;
    position: absolute;
    left: -40px;

    .ant-steps-item-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
  }
  .ant-steps-item-process.ant-steps-item-active
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title,
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-title,
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-subtitle,
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-description,
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #1a4975;
  }
  //   .ant-steps-item-active .ant-steps-item-container:after {
  //     content: "";
  //     width: 6px;
  //     background-color: #d6df22;
  //     position: absolute;
  //     height: 80px;
  //     top: 0;
  //     right: 29px;
  //     z-index: 2;
  //   }
  .ant-steps-vertical
    > .ant-steps-item.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail,
  .ant-steps-vertical
    > .ant-steps-item.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-icon {
    // background-color: #d6df22;
    z-index: 3;
  }
  .ant-steps-vertical .ant-steps-item {
    flex: 0 0 150px;
  }
  .ant-steps-vertical
    > .ant-steps-item.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-steps-vertical
    > .ant-steps-item.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail:after {
    display: none;
  }
  .steps-content {
    border: 1px solid #e7e8ea;
    padding-left: 3em;
    // margin-left: -30px;
    // margin-bottom: 30px;
    padding-right: 40px;
    padding-bottom: 0;
    max-width: 570px;
    width: 100%;
    box-shadow: 0 0.25em 0.375em rgba(50, 50, 93, 0.09),
      0 0.063em 0.188em rgba(0, 0, 0, 0.08);
    min-height: 600px;
    max-height: 100%;
    margin: 0 auto;
  }
}

.steps_wrapper {
  .ant-steps-item-container > .ant-steps-item-content {
    top: -40px;
    position: absolute;
    left: -40px;
  }

  .ant-steps-item-tail {
    height: 4px !important;
    // width: 24.9vw !important;
    width: 100% !important;
    top: 16px !important;

    &:after {
      height: 4px !important;
    }

    @media screen and (max-width: 1025px) {
      width: 28.5vw !important;
    }

    @media screen and (max-width: 768px) {
      width: 21.5vw !important;
    }
  }

  .ant-steps-item-icon {
    margin-left: 0 !important;
  }
}

.ant-page-header-heading-left {
  .ant-page-header-back {
    background-color: #d6df22;
    height: 27px;
    width: 27px;
    text-align: center;
    border-radius: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-page-header-back-button {
      line-height: 0 !important;
    }
    .anticon svg {
      fill: #1a4975;
    }
  }
  .ant-page-header-heading-title {
    font-size: 16px;
    font-weight: 500;
    color: #1a4975;
  }
}

.ant-page-header-heading-extra {
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    margin-left: 20px;
  }
  .ant-badge {
    display: flex;
  }
  .ant-badge-count {
    background: #7f7f7f;
  }
}

// Dashboard Style Start
.client-layout-wrap {
  .ant-layout-sider {
    background-color: #1a4975 !important;
    overflow-x: hidden !important;
    flex: 0 0 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
    .slider-wrap {
      hr {
        margin: 0;
        border-radius: 0;
        display: none;
      }
      .slider-content {
        background-color: #1a4975;
        // min-height: 200px;
        // border-bottom: 6px solid #d6df22;
        // padding: 20px 0;
        .close-menu {
          position: absolute;
          top: 5px;
          right: 10px;
          width: 30px;
          height: 30px;
          background: #1a4975;
          color: #fff;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          font-size: 16px;
        }
        p {
          margin-bottom: 0;
          padding-bottom: 0;
          font-size: 13px;
          font-weight: 400;
          color: #666;
          font-weight: 700;
        }
        .ant-avatar {
          width: 50px !important;
          height: 50px !important;
          //   background-color: #f2f2f2 !important;
          font-size: 36px !important;
          font-weight: 700;
          color: #1a4975;
          display: block;
          margin: 0 auto !important;
          span {
            color: #1a4975;
            transform: scale(1) translateX(-50%) translateY(-50%);
            line-height: 50px !important;
            font-size: 20px;
          }
        }
        label {
          font-size: 14px !important;
          font-weight: 700 !important;
          color: #1a4975 !important;
          line-height: 1;
          padding-top: 10px;
        }
      }
      .ant-menu-inline {
        border-right: 0;
        background-color: transparent;
        padding: 0;
        color: #fff;
        .ant-menu-item {
          border: 0;
          margin: 10px auto;
          margin-right: 0;
          padding: 0 0 0 30px !important;
          width: calc(100% - 30px) !important;
          svg {
            margin-right: 20px;
          }
          &:hover {
            color: #d6df22;
            svg {
              path {
                color: #d6df22;
              }
            }
          }
          &:active {
            background: none;
          }
          @media screen and (max-width: 768px) {
            // padding: 0 !important;
          }
        }
      }
      .ant-menu-inline-collapsed {
        > .ant-menu-item svg ~ span {
          display: inline-block;
          max-width: 0;
          opacity: 0;
          color: #ffffff;
          margin-right: 0;
        }
        .ant-menu-title-content {
          margin-left: 0;
        }
        .ant-menu-item-active {
          background-color: #1a4975;
          color: #d6df22;
          svg {
            path {
              //   fill: #d6df22;
              color: #d6df22;
            }
          }
        }
        .ant-menu-item {
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          //   height: min-content;
          margin-left: 7px;
          margin-right: 7px;
          border-right: none !important;
          color: #ffffff;
          &.menu-humberger {
            margin-top: 35px;
            margin-bottom: 60px;
            background-color: #1a4975 !important;
            svg {
              path {
                font-size: 22px;
                // fill: #fff !important;
                color: #fff;
              }
            }
          }
        }
        .ant-menu-item:hover {
          svg {
            color: #d6df22;
          }
        }
      }
      .ant-menu:not(.ant-menu-horizontal) {
        .ant-menu-item-selected {
          background-color: #1a4975;
          color: #d6df22;
          border-right: 5px solid #d6df22;
          svg {
            path {
              color: #d6df22;
            }
          }
          &::after {
            content: none;
          }
        }
      }
      .ant-menu-item {
        margin-top: 10px;
      }
    }
    &.ant-layout-sider-collapsed {
      width: 118px !important;
      min-width: 118px !important;
      flex: 0 0 118px !important;
      + .ant-layout.dashboard {
        margin-left: 118px !important;
      }
    }
    @media screen and (max-width: 768px) {
      &.ant-layout-sider-collapsed {
        width: 80px !important;
        min-width: 80px !important;
        max-width: 80px !important;
        flex: 0 0 80px !important;
        + .ant-layout.dashboard {
          margin-left: 80px !important;
        }
      }
    }
    @media screen and (max-width: 425px) {
      &.ant-layout-sider-collapsed {
        width: 50px !important;
        min-width: 50px !important;
        max-width: 50px !important;
        flex: 0 0 50px !important;
        + .ant-layout.dashboard {
          margin-left: 50px !important;
        }
      }
    }
  }
  .page-header {
    background-color: #fff;
    padding: 0 92px 0 92px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.08));
    // border-bottom: 6px solid #d6df22;
    // position: absolute;
    // right: 0;
    // left: 0;
    // line-height: 80px;
    .header-left {
      font-size: 18px;
      font-weight: 700;
      color: #1a4975;
      float: left;
      //   padding-left: 2rem;
      strong {
        font-weight: 700;
      }
    }
    .header-right {
      float: right;
      //   line-height: 80px;
      display: flex;
      align-items: center;
      // margin-top: 20px;
      svg {
        font-size: 24px;
      }
      .m-search {
        border: 1px solid #1a4975;
        top: 10px;
        margin-right: 10px;
        &.anticon {
          position: relative;
          svg {
            width: 23px;
            height: 23px;
            color: #7f7f7f;
          }
        }
      }
      .ant-input-affix-wrapper {
        width: 250px !important;
        height: 40px;
        padding: 0 15px;
        border-radius: 10px;
        background-color: #f4f4f4;
        border: 0;
        margin-right: 30px;
        @media screen and (min-width: 768px) and (max-width: 991px) {
          width: 200px !important;
        }
        input.ant-input {
          background-color: transparent;
          font-size: 13px;
          font-weight: 400;
        }
        .ant-input-prefix {
          margin-right: 20px;
        }
        .anticon {
          position: relative;
          svg {
            width: 18px;
            height: 18px;
            color: #1a4975;
          }
          &::after {
            content: "";
            width: 0;
            height: 12px;
            border-right: 1px solid #dbdbdb;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -9px;
          }
        }
      }
      .ant-badge {
        position: relative;
        top: 2px;
      }
      &.suspended {
        color: #310202;
      }
      &.failed {
        color: #e0b70d;
      }
      .ant-badge-count {
        background-color: #7f7f7f;
      }
    }
  }
  .ant-menu {
    background: #1a4975;
  }
  .ant-layout {
    &:not(.sider-layout) {
      background-color: #f8f8f8;
      overflow: visible !important;
      position: relative;
      margin-left: 250px;
    }
    // margin-left: 0 !important;
    // padding-left: 275px !important;
    .ant-layout-content {
      padding: 44px 148px !important;
      @media screen and (max-width: 1024px) {
        padding: 30px 40px !important;
      }
      @media screen and (max-width: 768px) {
        padding: 30px 4px !important;
      }
      &:not(.sendMoney) {
        background-color: #fff;
        // border-radius: 10px 10px 100px 10px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
        // margin-right: 40px;
        // margin-bottom: 40px;
        // margin-top: 90px;
        position: relative;
      }
      &.dashboard {
        h1 {
          &.primary-heading {
            margin-top: 0;
            font-size: 18px;
            font-weight: 700;
            color: #1a4975;
          }
        }
        .hr-line {
          margin: 5px 0 20px;
        }
      }
      .settings {
        .ant-card-bordered {
          background-color: #fff;
          border: 1px solid #eeeeee;
          border-radius: 10px;
          margin-bottom: 30px;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
          color: #666666;
          font-size: 16px;
          font-weight: 500;
          .ant-avatar-lg {
            width: 60px;
            height: 60px;
            line-height: 10px;
            margin-right: 15px;
            svg {
              path {
                fill: #1a4975;
              }
            }
          }
          .ant-tag {
            margin-left: 8px;
          }
        }
      }
      .faq {
        .ant-avatar-sm {
          width: 40px;
          height: 40px;
          line-height: 40px;
          background-color: #f2f2f2;
          font-size: 15px;
          font-weight: 500;
          color: #666666;
          margin-right: 15px;
        }
        .faq-title {
          font-weight: 500;
          font-size: 16px;
          color: #666666;
        }
        .ant-collapse-content-box {
          padding: 5px 67px 20px !important;
          //   color: #dce1e6;
          font-size: 18px;
          font-weight: 300;
          line-height: 22px;
          p {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .ant-collapse {
          .ant-collapse-item {
            &.ant-collapse-item-active {
              background-color: #1a4975;
              border-color: #1a4975;
              .ant-avatar-sm {
                background-color: #d6df22;
              }
              .faq-title {
                color: #fff;
              }
              .ant-collapse-extra {
                svg {
                  path {
                    fill: #d6df22;
                  }
                }
              }
            }
          }
        }
      }
    }
    .ant-collapse {
      border: 0;
      background-color: transparent;
      .ant-collapse-item {
        border: 1px solid #eee;
        background-color: #fff;
        border-radius: 10px;
        margin-bottom: 25px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
        .ant-avatar-lg {
          width: 60px;
          height: 60px;
          background-color: #f2f2f2;
          border-radius: 10px;
          margin-right: 15px;
          .ant-avatar-string {
            font-size: 18px;
            font-weight: 500;
            color: #1a4975;
            top: 50%;
            transform: scale(1) translateX(-50%) translateY(-50%) !important;
            width: 44px;
            white-space: normal;
            line-height: 24px;
          }
        }
        .trans-title {
          color: #666;
          font-size: 18px;
          font-weight: 500;
          @media screen and (max-width: 720px) {
            font-size: 12px;
          }
        }
        .trans-meta {
          color: #999999;
          font-size: 13px;
          font-weight: 400;
          text-transform: capitalize;
          line-height: 0.4;
          span {
            font-weight: 700;
            &.processing {
              color: #27bfdb;
            }
            &.pending {
              color: #444;
            }
            &.completed {
              color: #28955e;
            }
            &.cancelled {
              color: #ce1c1c;
            }
            &.failed {
              color: #e0b70d;
            }
            &.refunded {
              color: #9517ba;
            }
          }
        }
        .trans-details {
          margin-left: auto;
          text-align: right;
          @media screen and (max-width: 720px) {
            padding-right: 20px;
          }
          .trans-sender {
            color: #666;
            font-size: 18px;
            font-weight: 600;
            @media screen and (max-width: 720px) {
              font-size: 12px;
            }
          }
          .trans-receive {
            color: #1a4975;
            font-size: 13px;
            font-weight: 400;
          }
        }
        .trans-main {
          display: flex;
          justify-content: center;
          width: 100%;
        }
        .ant-collapse-extra {
          position: absolute;
          right: 13px;
          top: 42%;
          transform: translateY(-50%);
          svg {
            transition: all 0.4s ease-in-out;
            transform: rotate(0);
            width: 10px;
            path {
              fill: rgb(26, 73, 117);
            }
          }
          @media screen and (max-width: 720px) {
            right: 20px;
          }
        }
        .ant-collapse-content {
          border-top: 0;
          background-color: transparent;
        }
        &.ant-collapse-item-active {
          .ant-collapse-extra {
            svg {
              transform: rotate(180deg);
              path {
                fill: #1a4975;
              }
            }
          }
        }
        .trans-status {
          padding-left: 70px;
          .trans-progress {
            border-radius: 10px;
            background-color: #f4f5f6;
            padding: 40px 40px 30px;
            margin: 0 0 30px;
            @media screen and (max-width: 720px) {
              padding: 22px;
              margin: 0 0 0px;
            }
            .ant-steps {
              margin-bottom: 30px;
            }
            .ant-steps-item-finish {
              .ant-steps-item-icon {
                & > .ant-steps-icon {
                  .ant-steps-icon-dot {
                    background: #7ad28a;
                  }
                }
              }
              & > .ant-steps-item-container {
                & > .ant-steps-item-tail::after {
                  background-color: #7ad28a;
                  width: 100%;
                }
                & > .ant-steps-item-content {
                  & > .ant-steps-item-title {
                    color: #808a94;
                    font-size: 13px;
                    font-weight: 400;
                  }
                  & > .ant-steps-item-description {
                    color: #808a94;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 1.2;
                  }
                }
              }
              &.ant-steps-item-active {
                & > .ant-steps-item-container {
                  & > .ant-steps-item-content {
                    & > .ant-steps-item-description {
                      color: #7ad28a;
                    }
                  }
                }
              }
            }
            .ant-steps-item-wait {
              .ant-steps-item-icon {
                & > .ant-steps-icon {
                  .ant-steps-icon-dot {
                    background-color: #bbbcbd;
                  }
                }
              }
              & > .ant-steps-item-container {
                & > .ant-steps-item-tail::after {
                  background-color: #e3e3e3;
                }
                & > .ant-steps-item-content {
                  & > .ant-steps-item-title {
                    color: #808a94;
                    font-size: 13px;
                    font-weight: 400;
                  }
                  & > .ant-steps-item-description {
                    color: #808a94;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 1.2;
                  }
                }
              }
            }
            .ant-steps-dot {
              .ant-steps-item-tail {
                margin: 0 0 0 60px;
              }
            }
            .trans-p-details {
              color: #808a94;
              font-size: 12px;
              font-weight: 400;
              line-height: 1.5;
              display: flex;
              p {
                margin: 0;
                span {
                  color: #1a4975;
                  font-weight: 600;
                }
              }
              button.btn-green {
                margin-left: auto;
                background-color: #d6df22;
                color: #1a4975;
                font-size: 15px;
                font-weight: 700;
                padding: 8px 25px;
                border-radius: 100px;
                border: 0;
                display: inline-block;
                box-shadow: none;
                height: auto;
                span {
                  &.anticon {
                    position: relative;
                    top: -2px;
                    right: -10px;
                  }
                  svg {
                    width: 12px;
                    height: 12px;
                  }
                }
              }
              a.print-a-copy {
                display: none;
              }
              @media screen and (max-width: 720px) {
                display: block;
                max-width: 220px;
              }
            }
          }
          .trans-repeat-form {
            .ant-input-group {
              &.ant-input-group-compact {
                display: flex;
                @media screen and (max-width: 720px) {
                  display: block;
                }
                & > div {
                  margin-right: 30px;
                  border: 0;
                }
                .ant-input-group {
                  margin-right: 30px;
                }
                .ant-input {
                  border-radius: 6px 0 0 6px;
                  height: 42px;
                  background-color: #fff;
                  border-color: #e7e8ea;
                  color: #9aa0a7;
                  font-size: 14px;
                  font-weight: 400;
                }
                .ant-input-group-addon:last-child {
                  border-radius: 0 6px 6px 0;
                  height: 42px;
                  padding-left: 20px;
                  padding-right: 20px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #1a4975;
                  background-color: #fff;
                  border-color: #e7e8ea;
                }
                button {
                  background-color: #1a4975;
                  color: #fff;
                  font-size: 14px;
                  font-weight: 700;
                  padding: 8px 25px;
                  border-radius: 100px;
                  border: 0;
                  display: inline-block;
                  box-shadow: none;
                  height: 42px;
                  align-self: flex-end;
                  span {
                    &.anticon {
                      position: relative;
                      top: -2px;
                      right: -10px;
                    }
                    svg {
                      width: 12px;
                      height: 12px;
                    }
                  }
                }
              }
            }
            label {
              font-size: 14px;
              font-weight: 600;
              color: #333333;
              line-height: 1;
            }
          }
        }
        .ant-collapse-content {
          & > .ant-collapse-content-box {
            padding: 15px 30px 40px 0;
          }
        }
        .ben-detail {
          display: flex;
          align-items: center;
          .ant-avatar-lg {
            line-height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .ben-name {
            color: #666666;
            font-size: 18px;
            font-weight: 600;
          }
          .ben-meta {
            color: #999999;
            font-size: 13px;
            font-weight: 400;
            display: flex;
            img {
              height: 20px;
              margin-right: 10px;
              border-radius: 8px;
            }
          }
        }
        .ben-bank {
          padding-left: 85px;
          .bank-details {
            display: flex;
            justify-content: space-between;
            .ant-card-bordered {
              border: 0;
              background-color: #f4f5f6;
              border-radius: 10px;
              margin-right: 25px;
              margin-bottom: 30px;
              &:last-child {
                margin-right: 0;
              }
              .ant-card-body {
                padding: 20px;
                p {
                  font-size: 12px;
                  color: #808a94;
                  font-weight: 500;
                  margin-bottom: 0;
                  &.bank-data {
                    color: #1a4975;
                    font-weight: 400;
                  }
                }
              }
            }
          }
          .bank-action {
            .btn-blue {
              float: right;
            }
          }
        }
      }
    }
  }
}

button.btn-green {
  background-color: #d6df22;
  color: #1a4975;
  border-radius: 100px;
  padding: 10px 30px !important;
  border: 0;
  height: auto;
  font-size: 14px;
  font-weight: 700;
  & > span {
    & + .anticon {
      position: relative;
      top: -2px;
      right: -10px;
    }
  }
  &:hover,
  &:focus {
    background-color: #d6df22;
    color: #1a4975;
    opacity: 0.8;
  }
  &.w-272 {
    max-width: 289px;
    width: 272px;
  }
  &.w-289 {
    max-width: 289px;
    width: 289px;
  }
  &.w-200 {
    max-width: 289px;
    width: 200px;
  }
  &.h-40 {
    height: 40px !important;
  }
  &.h-48 {
    height: 48px !important;
  }
  &.with-icon {
    justify-content: space-between;
    display: flex;
    align-items: center;
  }
  &.center {
    justify-content: center;
    display: flex;
    align-items: center;
  }
  &.fs-18 {
    font-size: 18px !important;
  }
}

a.print-a-copy {
  display: none;
}

button.btn-danger {
  background-color: #df2222;
  color: #fcfcfc;
  border-radius: 100px;
  padding: 10px 30px;
  border: 0;
  height: auto;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 1em;
  & > span {
    & + .anticon {
      position: relative;
      top: -2px;
      right: -10px;
    }
  }
  &:hover,
  &:focus {
    background-color: #df2222;
    color: #fcfcfc;
    opacity: 0.8;
  }
}

button.btn-blue {
  background-color: #1a4975;
  color: #fff;
  border-radius: 100px;
  padding: 10px 30px;
  border: 0;
  height: auto;
  font-size: 14px;
  font-weight: 700;
  & > span {
    & + .anticon {
      position: relative;
      top: -2px;
      right: -10px;
    }
  }
  &:hover {
    background-color: #1a4975;
    color: #fff;
    opacity: 0.8;
  }
}

// Doc verification
.doc-verification-wrap {
  .ant-card {
    border-radius: 10px 10px 100px 10px;
  }
  button {
    &.ant-tabs-tab {
      &:focus {
        outline: none;
      }
    }
  }
  .ant-tabs {
    &.ant-tabs-top {
      .ant-tabs-nav {
        box-shadow: 0 0px 20px rgba(0, 0, 0, 0.06);
        height: 60px;
        padding: 0px 25px;
        margin-bottom: 4.5rem;
        margin-top: 10px;
        width: calc(100% - 20px);
        margin-left: auto;
        margin-right: auto;
        border-radius: 4px;
        .ant-tabs-nav-wrap {
          overflow: visible;
          .ant-tabs-nav-list {
            width: 100%;
            .ant-tabs-tab {
              &:not(:nth-child(3)) {
                &:after {
                  content: "";
                  position: absolute;
                  right: -20%;
                  height: 24px;
                  width: 2px;
                  //   background: #e4e4e4;
                  top: 15px;
                }
              }
              height: 68px;
              width: 26%;
              max-width: 26%;
              display: flex;
              justify-content: center;
              align-items: flex-start;
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 15px;
              position: relative;
              padding-top: 15px !important;
              margin-left: auto;
              margin-right: auto;
              word-break: break-word;
              white-space: normal;
              text-align: center;
              &.ant-tabs-tab-active {
                background: #d6df22;
                border-top: 3px solid #1a4975;
                .ant-tabs-tab-btn {
                  color: #1a4975;
                }
              }
              .ant-tabs-tab-btn {
                font-size: 18px;
                font-weight: 500;
                color: #333333;
              }
            }
            .ant-tabs-ink-bar {
              top: 0px !important;
              background: rgb(26, 73, 117) !important;
              height: 3px !important;
              display: none;
            }
          }
        }
      }
    }
  }
  label.input-label {
    font-weight: 600;
    color: #333;
  }
  // Doc verification ( Darshan )
  .form-group {
    .doc-label {
      color: #333333;
      font-weight: 500;
      font-size: 15px;
    }
    .doc-type-checkbox {
      display: flex;
      width: 100%;
      height: 50px;
      align-items: center;
      border: 1px solid #e7e8ea;
      padding-left: 15px;
      border-radius: 6px;
      &.ant-checkbox-wrapper-checked,
      &:hover {
        border-color: #1a4975 !important;
        .ant-checkbox {
          &.ant-checkbox-checked,
          .ant-checkbox-inner {
            background: #1a4975 !important;
            border-color: #1a4975 !important;
            border-radius: 4px;
            &:after {
              color: #fff !important;
              border-color: #fff !important;
              position: absolute;
              display: table;
              border: 2px solid #1a4975;
              border-top: 0;
              border-left: 0;
              -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
              transform: rotate(45deg) scale(1) translate(-50%, -50%);
              opacity: 1;
              -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46)
                0.1s;
              transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
              content: " ";
              left: 30%;
            }
          }
        }
        span {
          color: #1a4975 !important;
        }
      }
      .ant-checkbox-inner {
        width: 28px;
        height: 28px;
        border-radius: 4px;
        background: #e7e8ea;
        border-color: #e7e8ea;
      }
      span {
        color: #666666;
      }
    }
  }
  label.ant-form-item-required,
  .label-doc {
    color: #333333;
    font-size: 15px;
    font-weight: 500;
  }
  .cr-input,
  .ant-input,
  .ant-picker {
    height: 48px;
    max-height: 48px;
    border-radius: 6px;
    border-color: #e7e8ea;
    font-size: 15px;
    color: #666666;
  }
  label.ant-form-item-required,
  .label-doc {
    color: #333333;
    font-size: 15px;
    font-weight: 500;
  }
  .btn-blue {
    font-size: 18px !important;
  }
  .btn-tab-bottom {
    width: 200px;
    height: 48px !important;
    padding: 0 30px !important;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 15px;
    margin-left: 15px;
  }
  .ant-upload-drag {
    overflow: hidden;
    height: 260px !important;
    background: #f5faff !important;
    border-color: #d9dcdf !important;
    img {
      height: 200px !important;
      &.file-icon {
        height: 128px !important;
      }
    }
    @media screen and (max-width: 991px) {
      height: 175px !important;
      img {
        height: 150px !important;
      }
    }
    .ant-upload-drag-container {
      font-size: 15px !important;
      color: #333333 !important;
      small {
        font-weight: 500;
      }
    }
  }
  .doc-upload-wrapper {
    .upload-check-img {
      position: absolute;
      right: 0;
      z-index: 1;
      top: 10px;
    }
  }
  .finalised-card-wrapper {
    .finalised-wrapper {
      .fin-left {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f5faff;
        border: 2px #d9dcdf;
        border-style: dashed;
        border-radius: 4px;
        font-size: 28px;
        font-weight: bold;
        color: #f44336;
      }
      .fin-right {
        color: #9aa0a7;
        @media screen and (min-width: 768px) {
          width: calc(100% - 80px);
        }
        p {
          a {
            color: #1a4975;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

// Add beneficiary popup
.cr-input {
  height: 48px;
  max-height: 48px;
  border-radius: 8px !important;
  border-color: #e7e8ea;
  font-size: 15px;
  color: #666666;
  &.with-prefix {
    .ant-input-wrapper {
      .ant-input-group-addon {
        .cr-select {
          width: 100px;
        }
        border-radius: 10px;
        position: relative;
        left: 1px;
        z-index: 11;
        border: none;
        border-right: 1px solid #d9d9d9;
        border-left: 0px;
        height: 46px;
        top: 1px;
      }
      input {
        height: 48px;
        max-height: 48px;
        border-radius: 8px !important;
        border-color: #e7e8ea;
        font-size: 15px;
        color: #666666;
        padding-left: 8rem;
        outline: none;
        box-shadow: none;
        position: absolute;
        left: 0;
        &:hover,
        &:focus {
          border-color: #1a4975 !important;
          box-shadow: none !important;
        }
      }
    }
  }
  &:hover,
  &:focus {
    border-color: #1a4975 !important;
    box-shadow: none !important;
  }
}

.cr-textarea {
  border-radius: 8px !important;
  border-color: #e7e8ea;
  font-size: 15px;
  color: #666666;
  &:hover,
  &:focus {
    border-color: #1a4975 !important;
    box-shadow: none !important;
  }
}

.cr-select {
  .ant-select-selector {
    border-radius: 8px !important;
    height: 48px !important;
    align-items: center;
    .ant-select-selection-search > input {
      &::placeholder {
        height: 46px !important;
        line-height: 48px !important;
      }
      height: 46px !important;
      line-height: 48px !important;
    }
    .ant-select-selection-item {
      line-height: 48px !important;
    }
  }
  label.input-label {
    font-weight: 600;
    color: #333;
  }
  &:hover,
  &:focus,
  &.ant-select-focused {
    .ant-select-selector {
      border-color: #1a4975 !important;
      box-shadow: none !important;
    }
  }
}

.cr-country-option {
  .ant-select-item-option-content {
    display: flex;
    img {
      margin-right: 5px;
    }
  }
}

.cr-check {
  .ant-checkbox {
    &.ant-checkbox-checked {
      &:after {
        border: 1px solid #1a4975;
        border-radius: 4px;
      }
      .ant-checkbox-inner {
        background: #1a4975 !important;
        border-color: #1a4975 !important;
        &:after {
          left: 30%;
        }
      }
    }
    .ant-checkbox-input {
      &:focus + .ant-checkbox-inner {
        border-color: #1a4975 !important;
      }
    }
    &:hover,
    &:focus + .ant-checkbox-inner {
      .ant-checkbox-inner {
        border-color: #1a4975 !important;
      }
    }
  }
  .ant-checkbox-inner {
    width: 28px;
    height: 28px;
    background: #e7e8ea;
    border-color: #e7e8ea;
    border-radius: 4px;
  }
}

.cr-modal-wrapper {
  .cr-modal-inner-wrap {
    &.success-verify-wrap {
      .cr-form-item {
        border: 1px solid #e7e8ea;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 15px;
        .ant-form-item-label {
          width: 150px;
          label {
            margin-bottom: 0px;
            padding-right: 20px;
            &:before,
            &:after {
              display: none;
            }
          }
        }
      }
      .ant-modal-content {
        .ant-modal-footer {
          padding: 0 !important;
          margin: 0 !important;
        }
      }
      .btn-green {
        margin-top: 3rem;
      }
    }
    &.failed-verify-wrap {
      .cr-form-item {
        .ant-form-item-label {
          label {
            font-weight: 600;
          }
        }
      }
      .ant-modal-content {
        .ant-modal-footer {
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }
    .ant-modal-content {
      border-radius: 10px !important;
      .ant-modal-close {
        .ant-modal-close-x {
          box-shadow: 0px 0px 10px 0px #0000004d;
          border-radius: 50%;
          position: relative;
          top: -20px;
          left: 20px;
          background: #fff;
          font-size: 22px;
          color: #707070;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 46px !important;
          height: 46px !important;
          @media screen and (max-width: 575px) {
            top: 0px;
            left: 0px;
            box-shadow: none;
          }
        }
        &:focus,
        &:hover {
          outline: none;
        }
      }
      .ant-modal-header {
        border-radius: 10px 10px 0 0;
      }
      .ant-modal-footer {
        border-top: none;
        padding-bottom: 1.75rem;
      }
    }
  }
}

// .tab-card-wrapper {
//   box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
// }
.ant-select-arrow {
  color: #666666 !important;
}

// Media start from here
@media (max-width: 1199px) {
  .cc-wrapper {
    margin-top: -235px;
  }
}

@media (min-width: 769px) {
  .sendMoney {
    .step-m-header {
      display: none !important;
    }
  }
}

@media (max-width: 768px) {
  .cc-wrapper {
    margin-top: 30px;
  }
  .go-top {
    right: 0;
  }
}

@media screen and (max-width: 575px) {
  .ant-layout {
    &.dashboard {
      .page-header {
        line-height: 60px;
        padding: 0;
        .header-left {
          display: none !important;
        }
        .header-right {
          line-height: 20px;
        }
      }
    }
    .ant-layout-content:not(.sendMoney) {
      &.dashboard {
        border-radius: 10px;
        margin-right: 0px;
        margin-bottom: 0px;
        //margin-top: 75px;
        .doc-verification-wrap {
          .ant-card {
            .ant-card-body {
              padding: 5px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .ant-layout-content:not(.sendMoney) {
    &.dashboard {
      .doc-verification-wrap {
        .ant-card {
          .ant-card-body {
            padding: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .doc-verification-wrap {
    .ant-card {
      .ant-card-body {
        padding: 5px;
      }
    }
    .ant-tabs.ant-tabs-top {
      .ant-tabs-nav {
        padding: 0px 10px;
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              width: 30%;
              max-width: 30%;
              &:after {
                display: none;
              }
              .ant-tabs-tab-btn {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .doc-verification-wrap {
    .ant-card {
      .ant-card-body {
      }
    }
    .ant-tabs.ant-tabs-top {
      .ant-tabs-nav {
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              .ant-tabs-tab-btn {
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .doc-verification-wrap {
    .ant-tabs.ant-tabs-top {
      .ant-tabs-nav {
        padding: 0px 5px;
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              padding-top: 10px !important;
              .ant-tabs-tab-btn {
                font-size: 14px;
                max-width: 85px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .doc-verification-wrap {
    .ant-tabs.ant-tabs-top {
      .ant-tabs-nav {
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              .ant-tabs-tab-btn {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .m-search {
    // display: none !important;
  }
}

@media (max-width: 767px) {
  .sendMoney {
    .step-m-header {
      font-size: 16px;
      font-weight: 600;
      display: inline-block;
      clear: both;
      background-color: #1a4975;
      color: #fff;
      padding: 5px 15px;
      border-radius: 0 0 5px 5px;
    }
    .ant-steps-vertical .ant-steps-item-content {
      display: none;
    }
    .steps-content {
      max-width: 93%;
      padding: 0 15px 15px;
    }
    .ant-steps-vertical .ant-steps-item {
      flex: 0 0 90px;
    }
    // .ant-steps {
    //   max-width: 15%;
    // }
  }
  .client-layout-wrap {
    .page-header {
      .header-right {
        .header-search {
          display: none !important;
        }
      }
    }
  }
  .searchbar-show {
    .client-layout-wrap {
      .page-header {
        .header-right {
          .header-search {
            // display: flex !important;
            // transition: 0.5s ease-in-out;
            // width: 100% !important;
            // padding: 0 45px 0 15px;
            // position: fixed;
            // left: 0;
            // top: 0;
            // border-radius: 0;
            // height: 70px;
            // z-index: 1;
          }
        }
      }
    }
  }
}

.ant-carousel .slick-slide img {
  max-width: 140px;
  margin: 0 auto;
}

// DashBoard content Style ------------------------------------------------------------------>>>>
.dashboard-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
}

.balance-card-wrapper {
  margin-top: 40px;
  min-height: 140px;
  background-color: #eef5fb;
  border-radius: 12px;
  padding: 20px;
  .card-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }
  .card-content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 25px;
    @media screen and (max-width: "700px") {
      flex-direction: column;
    }
    .left-side {
      display: flex;
      align-items: flex-start;      
      @media screen and (max-width: "1150px") {
        flex-direction: column;
      }
      @media screen and (max-width: "700px") {
        // flex-direction: row;
      }
      .balance-figure {
        width: 160px;
        font-size: 27px;
        font-weight: 700;
        line-height: 33px;
      }
      .country-dd {
        margin-left: 47px;
        @media screen and (max-width: "1150px") {
          margin-left: 0;
          margin-top: 15px;
          margin-bottom: 30px;
        }
      }
    }
    .right-side {
      .right_side_grid {
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
      
          @media screen and (max-width:1499px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(1, 1fr);
          }
        }
      @media screen and (max-width: "560px") {
        .btn-1 {
          width: 260px;
        }
        .btn-2 {
          width: 260px;
        }
      }
      .stack-wrapper-wallet {
        display: flex;
      }
      .btn-1 {
        // width: 200px;
        height: 48px;
        color: #1a4975;
        width: 100%;
        justify-content: space-between;
      }
      .btn-2 {
        //width: 200px;
        height: 48px;
        background-color: #d6df22;
        color: #1a4975;
        border: none;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}

.wallet-card-wrapper {
  min-height: 500px;
  background-color: #eef5fb;
  border-radius: 12px;
  padding: 20px;
  .commonbank-card {
    height: 83px;
    min-width: 510px;
    border-radius: 4px;
    margin-top: 10px;
    background-color: #fff;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .flex-wrapper {
      display: flex;
      align-items: center;
      .line {
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
      }
      .subline {
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.5);
        margin-top: 10px;
      }
    }
    .flow-icon {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-color: #e2e1e1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
    .amount {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
    }
  }
  .btn-3 {
    height: 48px;
    width: 100%;
    background-color: #d6df22;
    color: #1a4975;
    border: none;
    margin-top: 20px;
  }
}

.card-content {
  .card-header1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .card-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
    }
    .header-link {
      color: #1890ff;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 700;
    }
  }
  /* scrollbar for Chrome, Safari and Opera */
  .bankcard-wrapper::-webkit-scrollbar {
    height: 5px;
  }
  .bankcard-wrapper::-webkit-scrollbar-track {
    background: #d2d1d1;
    border-radius: 10px;
  }
  .bankcard-wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  .bankcard-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .bankcard-wrapper {
    min-height: 465px;
    overflow: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

.receive-money-card {
  height: 100%;
  background-color: #eef5fb;
  border-radius: 12px;
  padding: 20px;
  .card-content {
    .flex-wrapper {
      display: flex;
      .note {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-top: 25px;
        margin-left: 15px;

        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }
}

.currency-update-card {
  height: 100%;
  background-color: #eef5fb;
  border-radius: 12px;
  padding: 20px;
  .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: 1280px) and (max-width: 1480px) {
      display: block;
    }
    @media screen and (max-width: 890px) {
      display: block;
    }
    .line-1 {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      margin-top: 8px;
    }
    .line-2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      margin-left: 26px;
      white-space: nowrap;
    }
    .btn {
      height: 32px;
      color: #1a4975;
      @media screen and (min-width: 1280px) and (max-width: 1480px) {
        margin-top: 10px;
      }
      @media screen and (max-width: 890px) {
        margin-top: 15px;
      }
    }
  }
}

.transaction-history {
  min-height: 462px;
  background-color: #eef5fb;
  border-radius: 12px;
  padding: 20px;
  margin-top: 40px;
  .card-content {
    /* scrollbar for Chrome, Safari and Opera */
    .transactionhistory-card-wrapper::-webkit-scrollbar {
      height: 5px;
    }
    .transactionhistory-card-wrapper::-webkit-scrollbar-track {
      background: #d2d1d1;
      border-radius: 10px;
    }
    .transactionhistory-card-wrapper::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
    .transactionhistory-card-wrapper::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .transactionhistory-card-wrapper {
      overflow: auto;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}

.transaction-card {
  min-height: 68px;
  min-width: 790px;
  background-color: #ffffff;
  border-radius: 4px;
  margin-top: 10px;
  .item-flex-all {
    display: flex;
    align-items: center;
  }
  .item-flex-all:last-child {
    margin-right: 8px;
  }
  .col-first {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #e2e1e1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
  }
  .col-second {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
  .col-third {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
  }
  .col-fourth {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  }
  .col-five {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  }
  .col-six {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
}

// Add Funds style start -------------------------------------------------------------->>>>
.add-funds {
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
  margin-top: 34px;
}

.breadCrumbItem {
  a {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.5);
  }
  a:last-child {
    color: #1a4975;
  }
}

//step css
.step-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  .step-container {
    width: 469px;
    min-height: 385px;
    background-color: #eef5fb;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 16px 19px;
    .step-box {
      width: 100%;
      height: 151px;
      background-color: #ffffff;
      border-radius: 10px;
      margin-top: 20px;
      padding: 16px 14px;
      .box-title {
        text-align: start;
        font-weight: 400;
        margin-bottom: 6px;
      }
    }
    .payid-line1 {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      margin-right: 8px;
    }
    .payid-line2 {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      margin-left: 4px;
    }
    .line-3 {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      margin-top: 24px;
      text-align: start;
    }
    .btn {
      height: 48px;
      width: 100%;
      background-color: #d6df22;
      border-radius: 6px;
      margin-top: 10px;
    }
  }
}

.step-header {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: start;
}

.step-line2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin-top: 4px;
  text-align: start;
}

// .chakra-input__right-element {
  // width: 120px !important;
  // height: 100% !important;
  // display: flex !important;
  // align-items: center !important;
// }

.header-DD {
  .ant-dropdown-menu-item {
    padding: 0;
    > .ant-dropdown-menu-title-content {
      padding: 5px 0 !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > .ant-btn {
        padding: 0 !important;
      }
    }
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
      padding: 0 !important;
    }
  }
}

.add-funds-step {
  .ant-steps-horizontal {
    > .ant-steps-item > .ant-steps-item-container > {
      .ant-steps-item-tail {
        margin-left: 0;
        padding: 0;
        background-color: #eef5fb;
        height: 12px;
        width: 41vw;
      }
    }
    > .ant-steps-item-process {
      > .ant-steps-item-container > {
        .ant-steps-item-tail::after {
          background-color: #1a4975;
          height: 12px;
          width: 50px;
        }
      }
      .ant-steps-item-tail {
        > .ant-steps-item-icon > .ant-steps-icon {
          opacity: 1;
          background-color: #d6df22;
          color: #ffffff;
        }
      }
    }
    > .ant-steps-item-wait > .ant-steps-item-container {
      > .ant-steps-item-tail::after {
        background-color: #eef5fb;
        height: 12px;
        border-radius: 10px;
      }
      > .ant-steps-item-tail {
        > .ant-steps-item-icon > .ant-steps-icon {
          opacity: 0.43;
          background-color: rgba(214, 223, 34, 0.43);
          color: #ffffff;
        }
      }
    }
    > .ant-steps-item-finish > .ant-steps-item-container {
      > .ant-steps-item-tail::after {
        background-color: #1a4975;
        height: 12px;
      }
    }
    > .ant-steps-item-tail {
      > .ant-steps-item-icon > .ant-steps-icon {
        opacity: 1;
        background-color: #d6df22;
        color: #ffffff;
      }
    }
    > .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #d6df22;
      color: #ffffff !important;
    }
    > .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: rgba(214, 223, 34, 0.43);
      color: #ffffff;
    }
    > .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #d6df22;
      color: #ffffff;
    }
  }
}

.CurrencyConverterstyle__CustomBtnContent-sc-j0fmva-30 {
  height: 36px;
}

.CurrencyConverterstyle__CustomDDContryWithFlag-sc-j0fmva-28 {
  img {
    height: 36px;
  }
}

.CurrencyConverterstyle__Heading-sc-j0fmva-19 {
  margin-bottom: 8px;
}

.CurrencyConverterstyle__Line-sc-j0fmva-20 {
  margin-bottom: 16px;
}

// .send-money
//   .ant-radio-wrapper-checked
//   > .ant-radio-checked
//   > .ant-radio-inner::after {
//   width: 32px;
//   height: 32px;
// }

.step2-aud{
  background-color: transparent !important;
  padding: 0 0 20px 0 !important;
  box-shadow: none !important;
  width: auto !important;
  // height: auto !important;
  .step2-box{
    padding: 0 !important;
    // height: auto !important;
  }
}

.step2-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 36px;
  .step2-container {
    width: 595px;
    height: 473px;
    background-color: #eef5fb;
    border-radius: 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 20px;
    .step2-box {
      width: 100%;
      height: 270px;
      background-color: #ffffff;
      border-radius: 4px;
      margin-top: 21px;
      padding: 21px;
      display: flex;
      .flex-wrapper {
        width: 100%;
        margin-top: 21px;
        .flex-line1 {
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;
        }
      }
      .flex-line2 {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        margin-top: 20px;
      }
      .checkbox {
        width: 60px;
        text-align: end;
      }
      .payid-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        margin-top: 15px;
        margin-bottom: 6px;
        display: flex;
      }
      .fee-wrapper {
        display: flex;
        margin-top: 8px;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        .feebox-2 {
          margin-left: 15px;
        }
      }
    }
    .btn {
      height: 50px;
      width: 100%;
      background-color: #d6df22;
      border-radius: 6px;
      margin-top: 45px;
    }
    .btn-cancel {
      height: 50px;
      width: 100%;
      background-color: transparent;
      border-radius: 6px;
      margin-top: 45px;
      border: 1px solid grey;
    }
  }
}

.step3-header {
  margin-top: 40px;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #000000;
}

.step3-subline {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #000000;
}

.step3-box {
  height: 280px;
  width: 448px;
  background-color: #eef5fb;
  border-radius: 12px;
  margin-top: 40px;
  padding: 20px;
  .flex-box {
    display: flex;
    justify-content: space-between;
    .left-side1 {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      color: #000000;
    }
    .left-side2 {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: #000000;
    }
    .right-side1 {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      color: #000000;
    }
    .right-side2 {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      color: #000000;
    }
  }
  .btn {
    height: 48px;
    width: 100%;
    background-color: #d6df22;
    border-radius: 6px;
    margin-top: 40px;
  }
}

//last congratulation step CSS
.last-step-wrapper {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  .last-step-container {
    width: 600px;
    height: 356px;
    background-color: #eef5fb;
    border-radius: 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 47px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .last-step-header {
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
      margin-top: 25px;
    }
    .last-step-subline {
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
      margin-top: 30px;
    }
  }
}

.currancy-menu{
  padding: 10px;
  background-color: white;
  width: 100px;
  outline: none;
}

.add_money{
  background-image: url('./assets/png/bg-element.png');
  background-size: cover;
  border-radius: 8px;
  padding: 16px;
}

.add-currancy-menu{
  background-color: #FFFFFF;
  padding: 8px 6px;
  border-radius: 50px;
  outline: none;
}

.btn_hover:hover{
  background-color: #175C84 !important;
}

.currancy-name:hover{
  background-color: #eeeeee;
}

.flags{
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 50%;
}
.css-1iqbypn:focus, .css-1iqbypn[data-focus] {
  box-shadow: none !important;
}

.input-line{
  outline: none;
}
.add_convert{
  button{
    background-color: transparent !important;
    border: none !important;
    &:hover{
      box-shadow: none !important;
    }
    svg{
      fill: white;
    }
  }
}
.convert_from{
  .convert_wrapper{
    max-width: 100%;
    align-items: center;
  }
  input{
    border: none !important;
    padding: 0 !important;
    height: auto !important;
    font-size: 24px !important;
  }
  button{
    background-color: transparent !important;
    border: none !important;
    &:hover{
      box-shadow: none !important;
    }
    span{
      color: black !important;    
    }
    svg{
      fill: black !important;
    }
  }
  .hgnNgn{
    justify-content: end !important;
    gap: 10px;
  }
}

.after_line {
  position: relative;

  &::after {
    content: "";
    border: 2px solid #d6df22;
    border-radius: 10px;
    width: 100px;
    margin-top: -5px;
    margin-left: 0;
    position: absolute;
    bottom: 10px;
    left: 0;
  }
}

.payment_radio{
  div:nth-child(2){
    width: 100% !important;
    padding-right: 0 !important;
  }
}

.congrets_box .last-step-container{
  background-color: transparent !important;
  box-shadow: none !important;
}

// .iframe_img{
//   div,img, iframe {
//     width: 100%;
//   }
//   a img{
//     width: 100% !important;
//   }
// }